<template>
  <div>
    <div class="bjffff"></div>
    <div class="content">
      <div class="contentBox">
        <div class="leftContent">
          <div class="lbCont" v-if="!lxData.resources">
            <a-carousel
              autoplay
              :autoplaySpeed="5000"
              :after-change="onAfterChange"
            >
              <div class="imgs" v-for="item in lbList">
                <img :src="$imgUrl + item.resource" alt="" srcset="" />
              </div>
            </a-carousel>
          </div>
          <div class="lbCont" v-if="lxData.resources">
            <a-carousel
              autoplay
              :autoplaySpeed="9000"
              :after-change="onLxAfterChange"
            >
              <div class="imgs" v-for="item in lxData.resources">
                <video ref="myVideo" controls muted v-if="item.type == '视频'">
                  <source :src="$imgUrl + item.path_name" type="video/mp4" />
                  您的浏览器不支持视频播放。
                </video>
                <img
                  :src="$imgUrl + item.path_name"
                  v-show="item.type != '视频'"
                />
              </div>
            </a-carousel>
          </div>
          <div class="wzContent">
            <div class="headBox">
              <div class="boxs">
                <div
                  class="box"
                  :class="item.id == lxTypeOn ? 'on' : ''"
                  v-for="item in lxTypeList"
                  @click="lxTypeClick(item)"
                >
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
              <div class="inpBox">
                <input
                  type="text"
                  placeholder="请搜索论坛标题"
                  v-model="seachName"
                  @keyup.enter="searchClick()"
                />
                <div class="ssBox iconfont icon-search"></div>
              </div>
            </div>
            <div class="contList">
              <div class="beizhu" v-if="lxData.content">
                注：{{ lxData.content }}
              </div>
              <div class="typeList">
                <div class="typeBoxs">
                  <div
                    class="box"
                    :class="index == typeOn ? 'on' : ''"
                    v-for="(item, index) in typeList"
                    @click="typeClick(index)"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div
                class="cont"
                :class="index == 0 ? 'contfirst-child' : ''"
                v-for="(item, index) in list"
              >
                <div class="contHead">
                  <div class="fbBox">
                    <div class="phoimg" @click="peoClick(item.member_id)">
                      <img :src="$imgUrl + item.memberavatar" alt="" />
                    </div>
                    <div class="box">
                      <div class="textBox">
                        <div class="name">{{ item.membername }}</div>
                        <div class="txt">论坛</div>
                      </div>
                      <!-- <div class="mobile">林传博 · 15868653801</div> -->
                    </div>
                  </div>
                  <div class="lxBox">
                    <!-- <div class="box">
                      <div class="yuan">#</div>
                      <div class="name">类型类型</div>
                    </div> -->
                    <div class="box hongb">
                      <div class="yuanimg">
                        <img src="../../assets/img/logo.png" alt="" srcset="" />
                      </div>
                      <div class="name">用户发布</div>
                    </div>
                  </div>
                </div>
                <div class="contTitle" @click="ltjump(item.id)">
                  <span v-if="item.recommend == 1">推荐</span>{{ item.name }}
                </div>
                <div class="contText c-3" @click="ltjump(item.id)">
                  {{ item.content }}
                </div>
                <div class="funBox">
                  <div class="boxs">
                    <div class="box">
                      <div class="iconfont icon-browse browse"></div>
                      <div class="num">{{ item.click }}</div>
                    </div>
                    <div class="box">
                      <div class="iconfont icon-message message"></div>
                      <div class="num">{{ item.childrenlength }}</div>
                    </div>
                    <div
                      class="box"
                      style="cursor: pointer"
                      @click="favoriteClick(item, index)"
                    >
                      <div
                        class="iconfont praise"
                        :class="
                          item.is_favorite
                            ? 'icon-praise_fill on'
                            : 'icon-praise'
                        "
                      ></div>
                      <div class="num">{{ item.praise }}</div>
                    </div>
                    <div class="box">
                      <div class="iconfont icon-send send"></div>
                      <div class="num">分享</div>
                      <div class="fxBox">
                        <div class="lianjieBox dis-cent">
                          <div class="iconfont icon-lianjie lianjie"></div>
                        </div>
                        <div class="name" @click="lianjieClick(item)">
                          复制链接
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="time">{{ item.create_time }}</div>
                </div>
              </div>
              <Kong v-if="list.length == 0" />
              <div class="fenye" v-if="totalCount > 10">
                <a-pagination
                  :default-current="page.pageNo"
                  v-model="page.pageNo"
                  :total="totalCount"
                  @change="pageOnChange"
                />
              </div>
              <!-- <div class="cont">
                <div class="contHead">
                  <div class="fbBox">
                    <div class="phoimg">
                      <img
                        src="https://img2.baidu.com/it/u=2427333929,299356392&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1719075600&t=d080018d58fc24ea0b56e3ab8cc336e6"
                        alt=""
                      />
                    </div>
                    <div class="name">名字</div>
                    <div class="txt txtlan">问答</div>
                  </div>
                  <div class="lxBox">
                    <div class="box">
                      <div class="yuan">#</div>
                      <div class="name">类型类型</div>
                    </div>
                    <div class="box hongb">
                      <div class="yuanimg">
                        <img src="../assets/img/logo.png" alt="" srcset="" />
                      </div>
                      <div class="name">用户发布</div>
                    </div>
                  </div>
                </div>
                <div class="contTitle">
                  <span>推荐</span
                  >标题标题标题标题标题标题标题标题标题标题标题标题
                </div>
                <div class="contText">
                  内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容
                </div>
                <div class="funBox">
                  <div class="boxs">
                    <div class="box">
                      <div class="iconfont icon-browse browse"></div>
                      <div class="num">100</div>
                    </div>
                    <div class="box">
                      <div class="iconfont icon-message message"></div>
                      <div class="num">100</div>
                    </div>
                    <div class="box">
                      <div class="iconfont icon-praise praise"></div>
                      <div class="num">100</div>
                    </div>
                    <div class="box">
                      <div class="iconfont icon-send send"></div>
                      <div class="num">分享</div>
                    </div>
                  </div>
                  <div class="time">2024-06-18 11:58:36</div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="rightContent">
          <div class="fbCont">
            <div class="txtBox" v-if="!user.id">
              <span class="txt">Hi~</span>
              请<span class="dl" style="cursor: pointer" @click="loginClick"
                >登录</span
              >后，发布内容
            </div>
            <!-- <div class="txtBox" v-if="user.id">
              <span class="txt">Hi~</span>
              <span class="dl"
                >{{ user.nickname ? user.nickname : user.name }} </span
              >欢迎加入用户社区
            </div> -->
            <div class="dlBox" v-if="user.id">
              <div class="img">
                <img :src="$imgUrl + user.avatar" />
              </div>
              <div class="text">
                {{ user.nickname ? user.nickname : user.name }}
              </div>
              <div class="text1">
                {{ user.company_name }}
              </div>
            </div>
            <div :class="!user.id ? 'tjBox' : 'tjBox1'">
              <!-- <div class="box" @click="tiwenShow">
                <div class="iconfont icon-tiwenti tiwenti"></div>
                <div class="name">提问题</div>
              </div>
              <div class="box" @click="chuangyiShow">
                <div class="iconfont icon-tianxieribao tiwenti"></div>
                <div class="name">写创意</div>
              </div> -->
              <div class="box" @click="wenzhangShow">
                <div class="iconfont icon-JGHandle tiwenti"></div>
                <div class="name">写论坛</div>
              </div>
              <div class="box" @click="fkJump">
                <div class="iconfont icon-gouwuche tiwenti"></div>
                <div class="name">购物车</div>
              </div>
              <!-- <div class="box" @click="dongtaiShow">
                <div class="iconfont icon-xuanfuanniufadongtai tiwenti"></div>
                <div class="name">发动态</div>
              </div> -->
            </div>
          </div>
          <div class="ggCont">
            <div class="headBox">公告</div>
            <div class="ggBox">
              <div class="textBox">
                <div
                  class="name"
                  style="width: 100%; text-align: justify; text-indent: 2em"
                >
                  在川田用户社区，探索川田的世界！在我们的商城中，您可以找到各类川田产品，满足不同需求；同时加入我们的论坛，和其他爱好者一起讨论使用技巧、维修心得，分享创作灵感。无论是新手还是专家，这里都是您缝纫旅程的最佳起点！
                </div>
              </div>
            </div>
          </div>
          <div class="ggCont">
            <div class="headBox">新闻</div>
            <div class="ggBox">
              <div
                class="textBox"
                v-for="item in newslist"
                @click="ggJump(item.id)"
              >
                <div class="name c-1">
                  {{ item.name }}
                </div>
                <div class="time">{{ item.create_time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gundongCont">
        <div class="title">川田合作</div>
        <div class="hzCont">
          <div class="zuoBox"></div>
          <div class="youBox"></div>
          <vue-seamless-scroll
            :data="listData"
            :class-option="classOption"
            class="warp"
          >
            <div class="ul-item">
              <div
                class="li-item"
                v-for="(item, index) in listData"
                :key="index"
              >
                <img :src="item.url" alt="" srcset="" />
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <Tiwen v-if="twshow" @show="tiwenShowJs" />
    <Chuangyi v-if="cyshow" @show="chuangyiShowJs" />
    <Dongtai v-if="dtshow" @show="dongtaiShowJs" />
    <Wenzhang v-if="wzshow" @show="wenzhangShowJs" />
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import Vue from "vue";
import Kong from "../component/kong.vue";
import Tiwen from "../component/tiwen.vue";
import Chuangyi from "../component/chuangyi.vue";
import Dongtai from "../component/dongtai.vue";
import Wenzhang from "../component/wenzhang.vue";
export default {
  metaInfo: {
    title: "浙江川田智能科技有限公司",
    meta: [
      {
        name: "description",
        content: "川田用户交流沟通，购买商品",
      },
      { name: "keywords", content: "川田,用户社区,交流,商城,缝纫机" },
    ],
  },
  components: {
    Kong,
    Tiwen,
    Chuangyi,
    Dongtai,
    Wenzhang,
    vueSeamlessScroll,
  },
  data() {
    return {
      listData: [
        {
          url: require("../../assets/img/logo/1-03.png"),
        },
        {
          url: require("../../assets/img/logo/1-03.png"),
        },
        {
          url: require("../../assets/img/logo/2-02.png"),
        },
        {
          url: require("../../assets/img/logo/3-02.png"),
        },
        {
          url: require("../../assets/img/logo/4-02.png"),
        },
        {
          url: require("../../assets/img/logo/5-02.png"),
        },
        {
          url: require("../../assets/img/logo/7-02.png"),
        },
        {
          url: require("../../assets/img/logo/8-02.png"),
        },
        {
          url: require("../../assets/img/logo/9-02.png"),
        },
        {
          url: require("../../assets/img/logo/10-02.png"),
        },
        {
          url: require("../../assets/img/logo/11-03.png"),
        },
        {
          url: require("../../assets/img/logo/12-02.png"),
        },
        {
          url: require("../../assets/img/logo/13-03.png"),
        },
        {
          url: require("../../assets/img/logo/14-02.png"),
        },
        {
          url: require("../../assets/img/logo/15-02.png"),
        },
        {
          url: require("../../assets/img/logo/16-02.png"),
        },
        {
          url: require("../../assets/img/logo/17-02.png"),
        },
        {
          url: require("../../assets/img/logo/18-02.png"),
        },
        {
          url: require("../../assets/img/logo/19-03.png"),
        },
        {
          url: require("../../assets/img/logo/20-03.png"),
        },
        {
          url: require("../../assets/img/logo/21-03.png"),
        },
        {
          url: require("../../assets/img/logo/22-03.png"),
        },
        {
          url: require("../../assets/img/logo/23-02.png"),
        },
        {
          url: require("../../assets/img/logo/24-03.png"),
        },
        {
          url: require("../../assets/img/logo/25-03.png"),
        },
        {
          url: require("../../assets/img/logo/26-02.png"),
        },
        {
          url: require("../../assets/img/logo/27-03.png"),
        },
        {
          url: require("../../assets/img/logo/28-02.png"),
        },
        {
          url: require("../../assets/img/logo/29-02.png"),
        },
        {
          url: require("../../assets/img/logo/30-02.png"),
        },
        {
          url: require("../../assets/img/logo/31-03.png"),
        },
        {
          url: require("../../assets/img/logo/32-02.png"),
        },
        {
          url: require("../../assets/img/logo/33-02.png"),
        },
        {
          url: require("../../assets/img/logo/34-02.png"),
        },
        {
          url: require("../../assets/img/logo/35-02.png"),
        },
        {
          url: require("../../assets/img/logo/36-02.png"),
        },
        {
          url: require("../../assets/img/logo/37-02.png"),
        },
        {
          url: require("../../assets/img/logo/38-02.png"),
        },
        {
          url: require("../../assets/img/logo/39-02.png"),
        },
        {
          url: require("../../assets/img/logo/40-03.png"),
        },
        {
          url: require("../../assets/img/logo/41-03.png"),
        },
        {
          url: require("../../assets/img/logo/42-03.png"),
        },
        {
          url: require("../../assets/img/logo/43-02.png"),
        },
        {
          url: require("../../assets/img/logo/29-02.png"),
        },
        {
          url: require("../../assets/img/logo/30-02.png"),
        },
        {
          url: require("../../assets/img/logo/31-03.png"),
        },
        {
          url: require("../../assets/img/logo/32-02.png"),
        },
        {
          url: require("../../assets/img/logo/33-02.png"),
        },
        {
          url: require("../../assets/img/logo/34-02.png"),
        },
      ],
      listData: [
        {
          url: require("../../assets/img/logo1/logo1 (1).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (2).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (3).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (4).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (5).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (6).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (7).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (8).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (9).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (10).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (11).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (12).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (13).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (14).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (15).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (16).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (17).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (18).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (19).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (20).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (21).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (22).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (23).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (24).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (25).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (26).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (27).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (28).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (29).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (30).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (31).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (32).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (33).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (34).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (35).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (36).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (37).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (38).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (39).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (40).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (41).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (42).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (43).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (44).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (45).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (46).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (47).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (38).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (39).jpg"),
        },
        {
          url: require("../../assets/img/logo1/logo1 (40).jpg"),
        },
      ],
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },
      typeOn: 0,
      typeList: ["最新", "热门"],
      twshow: false,
      cyshow: false,
      dtshow: false,
      wzshow: false,
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      list: [],
      newslist: [],
      user: {},
      seachName: "",
      lbList: [],
      lxTypeList: [],
      lxTypeOn: "",
      totalCount: 0,
      lxData: {},
    };
  },
  computed: {},
  created() {},
  mounted() {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    let code = searchParams.get("code");
    if (code) {
      this.codeGet(code);
    } else {
      this.userGet();
    }
    this.listGet();
    this.newsGet();
    this.rotationGet();
    // this.playVideo(); // 页面加载时自动播放
    this.typeGet();
    // let href = "https://suzkj.com" + window.location.hash.slice(1);
  },
  methods: {
    codeGet(code) {
      this.$get("/web/login_back", {
        code,
      }).then((res) => {
        console.log(res);
        window.localStorage.setItem("token1", res.data);
        window.localStorage.setItem("account1", res.unionid);
        setTimeout(() => {
          window.location.href = "https://suzkj.com/chuantian/#/index";
        }, 50);
      });
    },
    playVideo() {
      console.log(this.$refs.myVideo);
      this.$refs.myVideo[0].play();
    },
    pauseVideo() {
      this.$refs.myVideo.pause();
    },
    onAfterChange(i) {
      // if (this.lbList[i]) {
      // }
    },
    onLxAfterChange(e) {},
    typeGet() {
      this.$get("/common/configuration_gets", {
        type: "forum",
      }).then((res) => {
        console.log(res.data);
        this.lxTypeList = res.data;
        this.lxTypeList.unshift({
          id: "",
          resources: null,
          name: "全部类型",
        });
      });
    },
    lxTypeClick(item) {
      this.lxTypeOn = item.id;
      this.page.pageNo = 1;
      this.totalCount = 0;
      this.page.config_id = item.id;
      if (item.content) {
        item.content = item.content.replace(/<[^>]*>/g, "");
      }
      let spType = 0;
      if (item.resources) {
        console.log(item.resources);
        for (let i = 0; i < item.resources.length; i++) {
          const e = item.resources[i];
          let type = this.isVideo(e.path_name);
          if (type) {
            e.type = "视频";
            spType = 1;
          }
        }
      }
      this.lxData = item;
      if (spType == 1) {
        // setTimeout(() => {
        //   this.playVideo(); // 页面加载时自动播放
        // }, 500);
      }
      this.list = [];
      this.listGet();
    },
    isVideo(fileName) {
      const videoExtensions = [
        "mp4",
        "avi",
        "mov",
        "wmv",
        "flv",
        "webm",
        "mkv",
      ];
      const ext = fileName.split(".").pop().toLowerCase();
      return videoExtensions.includes(ext);
    },
    peoClick(id) {
      this.$router.push({
        path: "/userCont",
        query: {
          id,
        },
      });
    },
    rotationGet() {
      this.$get("/web/rotation", {
        type: 1,
      }).then((res) => {
        this.lbList = res.data;
      });
    },
    userGet() {
      this.$get("/web/member", {}, true).then((res) => {
        this.user = res.member;
      });
    },
    newsGet() {
      this.$get("/web/news_gets", {
        pageNo: 1,
        pageSize: 6,
      }).then((res) => {
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.content = e.content.replace(/<[^>]*>/g, "");
            e.create_time = e.create_time.split(" ")[0];
            this.newslist.push(e);
          }
        }
      });
    },
    fkJump() {
      let member = JSON.parse(window.localStorage.getItem("member"));
      if (!member) {
        this.$message.warning("请先登录！");
        return;
      }
      this.$router.push({
        path: "/cart",
        query: {},
      });
    },
    loginClick() {
      this.$router.push({
        path: "/login",
        query: {},
      });
    },
    pageOnChange(i) {
      this.list = [];
      this.page.pageNo = i;
      this.listGet();
    },
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/forum_index", this.page).then((res) => {
        this.judge = true;
        if (this.totalCount == 0) {
          this.totalCount = res.totalCount;
        }
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.content = e.content.replace(/<[^>]*>/g, "");
            this.list.push(e);
          }
        }
      });
    },
    favoriteClick(item, i) {
      this.$post("/web/favorite_save", {
        genre: 1,
        id: item.id,
      }).then((res) => {
        if (res.data.status == 1) {
          if (res.data.data) {
            this.list[i].praise++;
          } else {
            this.list[i].praise--;
          }
          this.list[i].is_favorite = res.data.data;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    lianjieClick(item) {
      const textarea = document.createElement("textarea");
      textarea.value = item.name + " " + window.location.href;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message.success("复制成功");
    },
    mouseover() {},
    mouseout() {},
    // 提问
    tiwenShow() {
      this.twshow = true;
    },
    tiwenShowJs() {
      this.twshow = false;
    },
    // 创意
    chuangyiShow() {
      this.cyshow = true;
    },
    chuangyiShowJs() {
      this.cyshow = false;
    },
    // 文章
    wenzhangShow() {
      let member = JSON.parse(window.localStorage.getItem("member"));
      if (!member) {
        this.$message.warning("请先登录！");
        return;
      }
      this.wzshow = true;
    },
    wenzhangShowJs(data) {
      if (data) {
        data.data.content = data.data.content.replace(/<[^>]*>/g, "");
        this.list.unshift(data.data);
      }
      this.wzshow = false;
    },
    // 动态
    dongtaiShow() {
      this.dtshow = true;
    },
    dongtaiShowJs() {
      this.dtshow = false;
    },
    ltjump(id) {
      this.$router.push({
        path: "/wzCont",
        query: {
          id,
        },
      });
    },
    ggJump(id) {
      this.$router.push({
        path: "/gonggao",
        query: {
          id,
        },
      });
    },
    searchClick() {
      this.page.name = this.seachName;
      this.page.pageNo = 1;
      this.totalCount = 0;
      this.list = [];
      this.listGet();
      this.$forceUpdate();
    },
    typeClick(i) {
      if (i == 1) {
        this.page.odb = "a.create_time desc";
      } else if (i == 0) {
        this.page.odb = "a.click desc";
      }
      this.page.pageNo = 1;
      this.totalCount = 0;
      this.list = [];
      this.typeOn = i;
      this.listGet();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.slick-slide {
  height: 240px;
  overflow: hidden;
}
.content {
  width: 100%;

  .contentBox {
    width: 1440px;
    margin: auto;
    display: flex;
    background-color: #f5f4f4;
    box-sizing: border-box;
    padding: 10px 15px;

    .leftContent {
      width: calc(100% - 370px);
      padding-right: 15px;
      box-sizing: border-box;

      .lbCont {
        border-radius: 10px;
        overflow: hidden;
        background-color: #fff;
        .imgs {
          height: 240px;
          video {
            width: 100%;
            height: 100%;
          }
          img {
            height: 100%;
            // object-fit: cover;
            display: block;
            // width: auto;
            // margin: auto;
            // width: 100%;
          }
        }
      }

      .wzContent {
        background-color: #fff;
        margin-top: 15px;

        .headBox {
          height: 60px;
          border-bottom: 1px solid #ebebeb;
          display: flex;
          line-height: 60px;
          align-items: center;
          padding-left: 37px;
          justify-content: space-between;
          .boxs {
            display: flex;
            align-items: center;
            .box {
              margin-right: 44px;
              height: 58px;
              cursor: pointer;

              .name {
                font-size: 16px;
              }
            }
          }
          .inpBox {
            width: 220px;
            border-radius: 35px;
            height: 35px;
            border: 1px solid #f66;
            position: relative;
            color: #f66;
            overflow: hidden;
            margin-right: 15px;
            input {
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              padding-left: 15px;
              color: #f66;
              position: absolute;
              top: 0;
            }
            .ssBox {
              top: 0;
              right: 0;
              height: 100%;
              width: 30px;
              line-height: 35px;
              font-size: 18px;
              position: absolute;
              cursor: pointer;
            }
          }

          .on {
            border-bottom: 3px solid #f66;

            // transition: all .3s linear;
            .name {
              font-weight: 700;
            }
          }
        }

        .contList {
          padding: 0 20px;
          .beizhu {
            color: #9c9c9c;
            margin-top: 10px;
            font-size: 12px;
          }
          .typeList {
            display: flex;
            .typeBoxs {
              border: 1px solid #ebebeb;
              border-radius: 5px;
              margin-top: 15px;
              margin-bottom: 15px;
              overflow: hidden;
              .box {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px;
                height: 35px;
                border-right: 1px solid #ebebeb;
                float: left;
                cursor: pointer;
              }
              .box:last-child {
                border-right: none;
              }
              .on {
                background: rgb(204, 3, 3);
                color: #fff;
              }
            }
          }
          .cont {
            padding: 20px 0 15px;
            border-bottom: 1px solid #ededed;

            .contHead {
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 13px;

              .fbBox {
                display: flex;
                align-items: center;
                flex: 1;

                .phoimg {
                  width: 40px;
                  height: 40px;
                  cursor: pointer;
                  img {
                    object-fit: cover;
                    border-radius: 50%;
                  }
                }

                .box {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  height: 40px;
                  .textBox {
                    display: flex;
                  }
                  .mobile {
                    margin-left: 10px;
                    color: #9c9c9c;
                    font-size: 13px;
                  }
                  .name {
                    color: #505766;
                    margin-left: 10px;
                    font-size: 15px;
                  }

                  .txt {
                    padding: 0 6px;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                    margin-right: 8px;
                    border: 1px solid #fb9900;
                    color: #fb9900;
                    border-radius: 2px;
                    margin-left: 15px;
                  }

                  .txtlan {
                    color: #7b8aff;
                    border: 1px solid #7b8aff;
                  }
                }
              }

              .lxBox {
                display: flex;
                align-items: center;

                .box {
                  padding: 0 12px;
                  line-height: 28px;
                  font-weight: 500;
                  color: #333;
                  text-align: center;
                  font-size: 12px;
                  border-radius: 14px;
                  background: #eff9ff;
                  margin-left: 16px;
                  position: relative;
                  // cursor: pointer;
                  display: flex;
                  align-items: center;

                  .yuan {
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    background: linear-gradient(to right, #00abee, #55c6f3);
                    font-weight: 600;
                    color: #fff;
                    font-size: 14px;
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }

                  .yuanimg {
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    font-weight: 600;
                    color: #fff;
                    font-size: 14px;
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }

                .hongb {
                  background-color: #ffefef;
                }
              }
            }

            .contTitle {
              font-size: 18px;
              font-weight: 600;
              color: #333;
              line-height: 22px;
              margin-bottom: 12px;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              span {
                margin-top: 1px;
                width: 36px;
                text-align: center;
                display: inline-block;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                font-weight: 600;
                margin-right: 8px;
                color: #fff;
                // background: #ff7a7a;
                background: #fb9900;
                position: relative;
                top: -1px;
                border-radius: 2px;
              }
            }

            .contText {
              color: #666;
              cursor: pointer;
            }

            .funBox {
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #999;
              margin-top: 5px;

              .boxs {
                display: flex;
                .box {
                  display: flex;
                  align-items: center;
                  margin-right: 15px;
                  position: relative;
                  .browse {
                    font-size: 18px;
                  }
                  .message {
                    font-size: 16px;
                  }
                  .praise {
                    font-size: 16px;
                  }
                  .on {
                    color: rgb(233, 14, 14);
                  }
                  .send {
                    font-size: 16px;
                  }

                  .num {
                    position: relative;
                    top: -1px;
                    font-size: 12px;
                    margin-left: 2px;
                  }
                  .fxBox {
                    position: absolute;
                    top: 0;
                    right: -100px;
                    box-shadow: 0 0 4px 2px #ebebeb;
                    background-color: #fff;
                    display: none;
                    width: 105px;
                    border-radius: 5px;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    .lianjieBox {
                      color: #fff;
                      background-color: #f56709;
                      border-radius: 50%;
                      height: 20px;
                      width: 20px;
                      margin-right: 5px;
                      .lianjie {
                        font-size: 12px;
                      }
                    }
                  }
                }
                .box:last-child {
                  cursor: pointer;
                }
                .box:hover {
                  .fxBox {
                    display: flex;
                  }
                }
              }
            }
          }
          .contfirst-child {
            padding-top: 5px;
          }
        }
      }
    }

    .rightContent {
      width: 370px;

      .fbCont {
        height: 190px;
        width: 100%;
        background: url(../../assets/img/bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% 100%;
        margin-bottom: 10px;
        padding: 0 10px;
        display: -ms-flexbox;
        display: flex;
        min-width: 0;
        background-color: #fff;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        border-radius: 6px;
        justify-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
      }

      .txtBox {
        position: absolute;
        color: #333;
        font-size: 16px;
        left: 40px;
        top: 30px;

        .txt {
          font-size: 26px;
          margin-right: 12px;
        }

        .dl {
          color: #0376e0;
        }
      }

      .dlBox {
        position: absolute;
        color: #333;
        font-size: 16px;
        left: 0;
        top: 0;
        width: 100%;
        height: 115px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        .text {
          font-size: 14px;
          margin-top: 5px;
        }
        .text1 {
          font-size: 12px;
          color: #9c9c9c;
        }
      }

      .tjBox1 {
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        padding-bottom: 30px;
        justify-content: space-evenly;
        display: flex;

        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          .tiwenti {
            font-size: 32px;
          }
          .tiwenti1 {
            font-size: 34px;
          }
        }

        .box:hover {
          .name {
            color: #f66;
            font-weight: 600;
          }
        }
      }

      .tjBox {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 30px;
        justify-content: space-evenly;
        display: flex;

        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          .tiwenti {
            font-size: 32px;
          }
          .tiwenti1 {
            font-size: 34px;
          }
        }

        .box:hover {
          .name {
            color: #f66;
            font-weight: 600;
          }
        }
      }

      .ggCont {
        background-color: #fff;
        margin-top: 15px;
        border-radius: 5px;
        overflow: hidden;

        .headBox {
          padding: 0 20px;
          font-size: 16px;
          font-weight: 500;
          color: #333;
          line-height: 47px;
          border-bottom: 1px solid #ededed;
          position: relative;
          display: -ms-flexbox;
          display: flex;
          min-width: 0;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -ms-flex-align: center;
          align-items: center;
        }

        .ggBox {
          padding: 20px;

          .textBox {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;
            .name {
              width: calc(100% - 90px);
            }

            .time {
              width: 90px;
              text-align: right;
            }
          }

          .textBox:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .gundongCont {
    width: 1440px;
    margin: auto;
    padding-top: 70px;
    .title {
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      margin-bottom: 10px;
    }
    .hzCont {
      overflow: hidden;
      height: 425px;
      position: relative;
      .zuoBox {
        height: 100%;
        width: 250px;
        background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
      }
      .youBox {
        height: 100%;
        width: 250px;
        background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
      }
      .warp {
        width: 1440px;
        margin: 0 auto;
        overflow: hidden;
        .ul-item {
          overflow: hidden;
          padding-top: 20px;
          .li-item {
            width: 225px;
            margin-right: 15px;
            margin-bottom: 15px;
            box-shadow: 10px 10px 14px 4px #f1f1f1;
            height: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
            float: left;
            border-radius: 5px;
            overflow: hidden;
            img {
              height: 300%;
              width: auto;
            }
          }
        }
      }
    }
  }
}
/deep/.ant-carousel .slick-dots li button {
  background-color: #000;
  opacity: 0.2;
}
/deep/.ant-carousel .slick-dots li.slick-active button {
  background-color: #000;
  opacity: 1;
}
</style>
